<template>
  <span>
    <b-sidebar
      sidebar-class="big_sidebar"
      id="add-nuiteq-meeting"
      v-model="show"
      right
      backdrop
      no-slide
      lazy
      shadow
      no-header-close
      no-close-on-backdrop
      no-close-on-esc
      @shown="shownSidebar"
      @hidden="closeBtn"
    >
      <template #header="{ hide }">
        <b-img
          src="/img/external/nuiteq.svg"
          width="150px"
          right
          class="mb-2"
        ></b-img>
        <strong class="ml-2" v-if="GetTitle">{{ GetTitle }}</strong>
      </template>
      <template #footer="{ hide }">
        <div v-if="!nuiteqAuthenticated" class="ml-2">
          <b-button @click="closeBtn">{{ $t("CLOSE") }}</b-button>
        </div>
        <div v-if="nuiteqAuthenticated" class="ml-2">
          <div v-show="step == 1">

            <b-button @click="closeBtn">{{ $t("CLOSE") }}</b-button>

            <b-button
              v-if="!isChange"
              @click="saveMeeting(true)"
              :disabled="saving || disabledSave"
              variant="secondary"
              class="btn-fill"
              >{{ $t("SAVE_DRAFT") }}</b-button
            >

            <b-button
              :disabled="disabledStep2"
              @click="step = 2"
              variant="primary"
              class="btn-fill"
              >{{ $t("NEXT") }}</b-button
            >
            <b-button
              v-if="(CreateMeetingDTO.message_uuid != '' || message_uuid != '') && !isChange"
              @click="RemoveDraft"
              :disabled="saving"
              variant="danger"
              class="btn-fill float-right"
              >{{ $t("REMOVE") }}</b-button
            >
          </div>

          <div v-show="step == 2">
            <b-button @click="step = 1">{{ $t("BACK") }}</b-button>

            <b-button
              v-if="!isChange"
              @click="saveMeeting(true)"
              :disabled="saving || disabledSave"
              variant="secondary"
              class="btn-fill"
              >{{ $t("SAVE_DRAFT") }}</b-button
            >

          <b-button
            :disabled="disabledStep3"
            v-if="!isChange"
            @click="step = 3"
            variant="primary"
            class="btn-fill"
            >{{ $t("NEXT") }}</b-button
          >
          
          <b-button
            :disabled="disabledStep3"
            v-if="isChange"
            @click="step = 4"
            variant="primary"
            class="btn-fill"
            >{{ $t("NEXT") }}</b-button
          >

            <b-button
              v-if="(CreateMeetingDTO.message_uuid != '' || message_uuid != '') && !isChange"
              @click="RemoveDraft"
              :disabled="saving"
              variant="danger"
              class="btn-fill float-right"
              >{{ $t("REMOVE") }}</b-button
            >
          </div>

          
          <div v-show="step == 3">
            <b-button @click="step = 2">{{ $t("BACK") }}</b-button>

            <b-button
              v-if="!isChange"
              @click="saveMeeting(true)"
              :disabled="saving || disabledSave"
              variant="secondary"
              class="btn-fill"
              >{{ $t("SAVE_DRAFT") }}</b-button
            >

            <b-button
              @click="step = 4"
              variant="primary"
              class="btn-fill"
              >{{ $t("NEXT") }}</b-button
            >

            <b-button
              v-if="(CreateMeetingDTO.message_uuid != '' || message_uuid != '') && !isChange"
              @click="RemoveDraft"
              :disabled="saving"
              variant="danger"
              class="btn-fill float-right"
              >{{ $t("REMOVE") }}</b-button
            >
          </div>

          <div v-show="step == 4">
            <b-button v-if="isChange" @click="step = 2">{{ $t("BACK") }}</b-button>
            <b-button v-if="!isChange" @click="step = 3">{{ $t("BACK") }}</b-button>

            <b-button
              v-if="!isChange"
              @click="saveMeeting(true)"
              :disabled="saving || disabledSave"
              variant="secondary"
              class="btn-fill"
              >{{ $t("SAVE_DRAFT") }}</b-button
            >

            <b-button
            :disabled="disabledSend"
            v-if="!isChange"
            @click="saveMeeting(false)"
            variant="primary"
            class="btn-fill"
            >{{ $t("SUBMIT") }}</b-button
          >

          <b-button
            :disabled="disabledSend"
            @click="UpdateBtn"            
            v-if="isChange"
            variant="primary"
            class="btn-fill"
            >{{ $t("UPDATE") }}</b-button
          >

            <b-button
              v-if="(CreateMeetingDTO.message_uuid != '' || message_uuid != '') && !isChange"
              @click="RemoveDraft"
              :disabled="saving"
              variant="danger"
              class="btn-fill float-right"
              >{{ $t("REMOVE") }}</b-button
            >
          </div>
        </div>
      </template>

      <b-overlay :show="!CreateMeetingDTO.isDraft && saving" rounded="sm">
        <div class="pb-4" v-if="loaded">
          <div v-if="settings" class="px-3 py-2 view-content create-message">
            <div v-if="!nuiteqAuthenticated">
              <b-form-input
                trim
                class="mt-2"
                input-id="subject"
                autocomplete="off"
                :placeholder="$t('USERNAME')"
                v-model="username"
              ></b-form-input>

              <b-form-input
                trim
                class="mt-2"
                type="password"
                input-id="subject"
                autocomplete="off"
                :placeholder="$t('PASSWORD')"
                v-model="password"
              ></b-form-input>

              <b-button
                @click="authenticatedBtn"
                variant="primary"
                class="btn-fill"
                >{{ $t("LOGIN") }}</b-button
              >
            </div>
            <div v-if="nuiteqAuthenticated && CreateMeetingDTO">
              <div v-show="step == 1">
                <InputSubject
                  v-model="CreateMeetingDTO.subject"
                  @valid="(n) => (validatedSubject = n)"
                  :settings="settings"
                  :limit="256"
                ></InputSubject>

                <div class="info-header mt-2">{{ $t("ADD_RECIPIENTS") }}</div>

                <InputParticipants
                  :key="'InputParticipants_' + externalParticipantsKey"
                  :message_uuid="CreateMeetingDTO.message_uuid"
                  message_type="nuiteq-meeting"
                  is-meeting
                  :participants="CreateMeetingDTO.participants"
                  :external_participants="
                    CreateMeetingDTO.external_participants
                  "
                  @IsTyping="IsTyping"
                  @UpdatedParticipants="UpdatedParticipants"
                ></InputParticipants>

                <div class="info-header mt-2">
                  {{ $t("NUITEQ.STAGE_MEETING") }}
                </div>

                <div v-if="settings.user_meetings_enabled" class="mt-2">
                  <div v-if="!selectedMeeting" class="mb-2">
                    {{ $t("NONE_SELECTED") }}
                  </div>

                  <div v-if="selectedMeeting" class="mb-2">
                    {{ selectedMeeting.name }}
                  </div>

                  <b-button
                    size="sm"
                    variant="primary"
                    @click="generateStageMeeting"
                  >
                    {{ $t("NUITEQ.GENERATE_MEETING") }}
                  </b-button>

                  <b-button size="sm" variant="primary" @click="viewMeetings">
                    {{ $t("NUITEQ.CREATED_MEETING_IN_STAGE") }}
                  </b-button>

                  <b-button
                    size="sm"
                    variant="primary"
                    v-b-modal.save-new-meeting
                  >
                    <i class="fal fa-plus"></i>
                    {{ $t("NUITEQ.CREATE_MEETING") }}
                  </b-button>
                </div>

                <div class="info-header mt-2">{{ $t("TIME") }}</div>
                
             <b-alert show variant="warning" class="pl-1 pt-1 pb-1 pr-1" v-if="!isChange && eventExpired">
              {{ $t('EVENT_EXPIRED') }}
            </b-alert>


                <PickDate
                v-model="CreateMeetingDTO.settings.meeting_start_date"
                :use-label="$t('FROM')"
                use-clock
                @valid="(n) => (validMeetingStart = n)"></PickDate>

              <PickDate              
                :start-date="CreateMeetingDTO.settings.meeting_start_date"
                v-model="CreateMeetingDTO.settings.meeting_end_date"
                :use-label="$t('TO')"
                use-clock
                @valid="(n) => (validMeetingEnd = n)"></PickDate>

                <PickRecurrence
                v-model="CreateMeetingDTO.recurrence"
                 @valid="(n) => (validRecurrence = n)"
                 @changed="() => (recurrenceIndex += 1)"
                :startDate="CreateMeetingDTO.settings.meeting_start_date"
              :endDate="CreateMeetingDTO.settings.meeting_end_date"
                ></PickRecurrence>
                
              
              <PickRecurrence 
                :key="'recurrenceIndex_' + recurrenceIndex"
                :recurrence="CreateMeetingDTO.recurrence"
                :startDate="CreateMeetingDTO.settings.meeting_start_date"
                :endDate="CreateMeetingDTO.settings.meeting_end_date"
                text-only></PickRecurrence>


                <div class="info-header mt-2">{{ $t("ADD_FILES") }}</div>

                <div :key="'AddFiles' + CreateMeetingDTO.message_uuid">
                  <InputFiles
                    :message_uuid="CreateMeetingDTO.message_uuid"
                    v-model="CreateMeetingDTO.attachments"
                    :cannot-upload="$t('DRAFT.SEFOS_UPLOAD')"
                    :max-size="200"
                    @valid="(n) => (validatedFiles = n)"
                  ></InputFiles>
                </div>
              </div>
              <div v-show="step == 2">
                <div class="info-header mt-2">
                  {{ $t("INVITATION_INFORMATION") }}
                </div>

                <InputExternalText
                  v-model="CreateMeetingDTO.external_text"
                  @valid="(n) => (validatedExternalText = n)"
                  :settings="settings"
                  :rows="5"
                  is-meeting
                  :limit="1500"
                ></InputExternalText>

                <div class="info-header mt-2">{{ $t("MEETING_NOTES") }}</div>

                <InputBody
                  v-model="CreateMeetingDTO.body"
                  @valid="(n) => (validatedBody = n)"
                  :settings="settings"
                  :rows="8"
                  :limit="20000"
                ></InputBody>
              </div>

              <div v-show="step == 3">
                <div class="info-header mt-2">{{ $t("MESSAGES.LOA_LEVELS") }}</div>
  
                <InputLoa v-model="CreateMeetingDTO.settings.loa_level"></InputLoa>
              </div>
  
              <div v-show="step == 4">                
                <div class="info-header mt-2">
                  {{ $t("MESSAGES.NOT_REGISTERED_INFORMATION") }}
                </div>

              <div class="mt-2" :key="'NotRegistedLoa_' + CreateMeetingDTO.settings.loa_level">
                  <b-alert
                    show
                    variant="warning"
                    class="mb-2"
                    v-if="CreateMeetingDTO.external_participants.length == 0"
                    >{{ $t("NO_RECIPIENTS") }}</b-alert
                  >

                  <div
                    v-for="(
                      item, index
                    ) in CreateMeetingDTO.external_participants"
                    :key="index"
                    class="NotRegistered"
                  >
                    <NotRegistered
                      @change="UpdatedNotRegistered"
                      v-model="CreateMeetingDTO.external_participants[index]"
                      @remove="RemoveNotRegistered(index)"
                      message_type="message"
                      :methods="methods"
                      :showSefos="item.showSefos"
                      :loa_level="CreateMeetingDTO.settings.loa_level"
                    ></NotRegistered>
                  </div>
                </div>
                
              <b-button
              @click="step = 1"
              variant="secondary"
              >{{ $t("ADD") }}</b-button
            >
              </div>

              <div v-show="step == 22">
                <div :key="meetingsNotInTrash.length">
                  <b-button class="no-border" @click="getMeetings"
                    ><i class="fal fa-sync"></i
                  ></b-button>
                  {{ $t("NUITEQ.SELECT_MEETING") }}

                  <b-table
                    striped
                    hover
                    per-page="10"
                    @row-clicked="setMeeting"
                    :current-page="currentMeetingPage"
                    :items="meetingsNotInTrash"
                    :fields="nuiteqMeetingsFields"
                  >
                    <template v-slot:cell(name)="data">
                      <b-button
                        variant="primary"
                        target="blank"
                        size="sm"
                        @click="setMeeting(data.item)"
                        >{{ $t("USE_THIS_MEETING") }}</b-button
                      >
                      {{ data.item.name }}
                    </template>

                    <template v-slot:cell(id)="data">
                      <b-button
                        variant="primary"
                        target="blank"
                        size="sm"
                        @click="joinBoard(data.item.id)"
                        >{{ $t("JOIN") }}</b-button
                      ><span class="hidden-not-mobile">{{
                        data.item.name
                      }}</span>
                    </template>
                  </b-table>

                  <b-pagination
                    v-model="currentMeetingPage"
                    :total-rows="meetingsNotInTrash.length"
                    per-page="10"
                    aria-controls="my-table"
                  ></b-pagination>
                </div>

                <b-button @click="cancelSelectMeeting">{{
                  $t("CANCEL")
                }}</b-button>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>

    
    <b-modal :ok-title="$t('EDIT')" @ok="UpdateMeeting" hide-header ok-variant="primary" centered v-model="showRecurringOptions" id="editMeetingModal">
      <b-form-group>
        <b-form-radio v-model="editRecurring" name="edit-recurring-single" value="SINGLE">{{ $t('EDIT_RECURRENCE_SINGLE') }}</b-form-radio>
        <b-form-radio v-model="editRecurring" name="edit-recurring-multiple" value="MULTIPLE">{{ $t('EDIT_RECURRENCE_MULTIPLE') }}</b-form-radio>
        <b-form-radio v-model="editRecurring" name="edit-recurring-all" value="ALL">{{ $t('EDIT_RECURRENCE_ALL') }}</b-form-radio>              
      </b-form-group>
    </b-modal>


    <b-modal
      id="save-new-meeting"
      hide-header
      @cancel="cancelSave"
      @hidden="closedNewMeeting"
      @ok="okSave"
      centered
      :key="'SaveMeeting' + SaveMeetingIndex"
      :ok-disabled="meetingName == ''"
      :cancel-title="$t('CANCEL')"
      :ok-title="$t('SAVE')"
    >
      <b-img
        src="/img/external/nuiteq.svg"
        alt="nuiteq logo"
        class="mb-2"
      ></b-img>
      <div>
        {{ $t("NUITEQ.CREATE_MEETING_TITLE") }}

        <b-form-input
          trim
          :placeholder="$t('NUITEQ.MEETING_NAME')"
          v-model="meetingName"
          autocomplete="off"
          :name="meetingNameInput"
        ></b-form-input>

        <b-form-input
          class="mt-2"
          @click="meetingPasswordType = 'password'"
          :type="meetingPasswordType"
          autocomplete="off"
          @blur="meetingPasswordBlur"
          :name="meetingPasswordInput"
          :placeholder="$t('PASSWORD')"
          v-model="meetingPassword"
        ></b-form-input>

        <b-form-checkbox
          id="nuiteq-meeting-waiting-room"
          class="mt-2"
          v-model="meetingWaitingroom"
          name="checkbox-1"
          :value="true"
          switch
          :unchecked-value="false"
        >
          {{ $t("NUITEQ.WAITING_ROOM_ACTIVE") }}
        </b-form-checkbox>
      </div>
    </b-modal>
  </span>
</template>
<script>
import { DateTime } from "luxon";
import InputSubject from "../../Input/Subject";
import InputBody from "../../Input/Body";
import InputExternalText from "../../Input/ExternalText";
import InputParticipants from "../../Input/Participants";
import InputFiles from "../../Input/Files";
import NotRegistered from "../NotRegistered";
import PickDate from "../../Input/PickDate";
import PickRecurrence from "../../Input/PickRecurrence";
import InputLoa from "../../Input/Loa";
export default {
  props: {
    isChange: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    show: {
      default: false,
      type: Boolean,
    },
    message_uuid: {
      default: "",
      type: String,
    },
    startMeeting: {
      default: "",
      type: String,
    },
    endMeeting: {
      default: "",
      type: String,
    }
  },
  components: {
    InputSubject,
    InputBody,
    InputExternalText,
    InputParticipants,
    InputFiles,
    NotRegistered,
    PickDate,
    PickRecurrence,
    InputLoa
  },
  data() {
    return {
      recurrenceIndex: 0,
      editRecurring: "SINGLE",
      showRecurringOptions: false,
      externalParticipantsKey: 0,
      validRecurrence: true,
      validMeetingStart: true,
      validMeetingEnd: true,
      validatedSubject: true,
      validatedBody: true,
      validatedExternalText: true,
      saving: false,
      isTyping: false,
      timeout: 2000,
      canSave: false,
      loaded: false,
      timer: null,
      validatedFiles: true,
      OriginalDTO: null,
      CreateMeetingDTO: {
        join_url: "",
        boardId: "",
        recurrence: null,
        user_uuid: "",
        functionbox_uuid: "",
        action: "INIT",
        message_uuid: "",
        conversation_uuid: "",
        subject: "",
        body: "",
        attachments: [],
        participants: [],
        external_text: "",
        external_participants: [],
        settings: {
          loa_level: 1,
          open_to_date: null,
          open_from_date: null,
          require_response: 1,
          meeting_start_date: "",
          meeting_end_date: "",
        },
        custom_identifier: "",
        client: "web",
        response_to_message_uuid: this.message_uuid,
        webhook: "",
        isDraft: true,
      },
      SaveMeetingIndex: 0,
      meetingNameInput: "meetingNameInput_" + Date.now(),
      meetingPasswordInput: "meetingPasswordInput" + Date.now(),
      meetingPasswordType: "text",
      settings: null,
      forceRedrawRecipientsIndex: 0,
      currentMeetingPage: 1,
      selectedMeeting: {
        id: "",
        name: this.$t("NUITEQ.GENERATED_MEETING"),
      },
      nuiteqMeetings: [],
      nuiteqMeetingsFields: [
        {
          key: "name",
          label: this.$t("NAME"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "password",
          label: this.$t("PASSWORD"),
          class: "text-break clickable",
          sortable: false,
        },
        {
          key: "id",
          label: "",
          class: "text-break clickable",
          sortable: false,
        },
      ],
      username: "",
      password: "",
      addedIndex: 0,
      step1index: 0,
      step: 1,
      methods: [],
      loading: false,
      process: 1,
      meetings: [],
      nuiteqAuthenticated: false,
      meetingName: "",
      meetingPassword: "",
      meetingWaitingroom: false,
      isDateTimeValid: false,
      recurrence: null
    };
  },
  methods: {
    RemoveNotRegistered(index)
    {
      this.SefosDTO.external_participants.splice(index, 1);
      this.externalParticipantsKey++;
    },
    async RemoveDraft() {
      this.loading = true;
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.$http
              .post(self.user.hostname + "/meetings/secure/remove", {
                message_uuid: self.CreateMeetingDTO.message_uuid == "" ? self.message_uuid : self.CreateMeetingDTO.message_uuid,
                user_uuid: "",
                functionbox_uuid: "",
              })
              .then(() => {
                self.loading = false;
                self.$noty.info(self.$t("REMOVED"));
                self.closeBtn();
              })
              .catch(() => {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
    UpdatedNotRegistered() {
      this.debounceSave();
    },
    IsTyping(val) {
      this.isTyping = val;
    },
    SetValidatedNotRegistered(payload) {
      this.CreateMeetingDTO.external_participants[payload.index].validated =
        payload.value;
      let allValidated = this.CreateMeetingDTO.external_participants.filter(
        function (searchItem) {
          return (
            searchItem.validated == true ||
            searchItem.authentication_method == "NOT_SET"
          );
        }
      );
      if (
        allValidated.length ==
        this.CreateMeetingDTO.external_participants.length
      ) {
        //this.debounceSave();
        //console.log("ALLVALIDATED TRUE");
      }
      //if (payload.value) {
      this.debounceSave();
      //}
    },
    UpdatedParticipants(updated) {
      this.CreateMeetingDTO.participants = updated.participants;
      this.CreateMeetingDTO.external_participants =
        updated.external_participants;
    },
    debounceSave() {
      if (!this.isChange) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.saveMeeting(true);
        }, this.timeout);
      }
    },
    meetingPasswordBlur() {
      if (this.meetingName == "") {
        this.meetingPasswordType = "text";
      }
    },
    closedNewMeeting() {
      this.SaveMeetingIndex++;
      this.cancelSave();
    },
    generateStageMeeting() {
      this.selectedMeeting = {
        id: "",
        name: this.$t("NUITEQ.GENERATED_MEETING"),
      };
      this.CreateMeetingDTO.boardId = "";
    },
    viewMeetings() {
      this.getMeetings();
      this.step = 22;
    },
    setMeeting(item) {
      this.CreateMeetingDTO.boardId = item.id;
      this.selectedMeeting = item;
      this.step = 1;
    },
    cancelSelectMeeting() {
      this.step = 1;
    },
    async shownSidebar() {
      await this.$store.dispatch("user/fetchUser");
      await this.getAuthenticated();
      this.settings = await this.GetSettings();
      this.methods = await this.GetMethods();
      if (this.message_uuid != "") {
        await this.GetInformation();
      } else {
        this.InitSettings();
      }
    },
    InitDone() {
      this.loading = false;
      this.loaded = true;
      setTimeout(() => {
        this.canSave = true;
      }, this.timeout);
      this.CreateMeetingDTO.action = "NEW";
    },
    async GetInformation() {
      try {
        let result = await this.$http.get(
          this.user.hostname + "/meetings/info/" + this.message_uuid
        );
        this.CreateMeetingDTO = result.data;  
        this.OriginalDTO = JSON.parse(JSON.stringify(result.data))
        this.recurrence = result.data.recurrence;
        if(this.startMeeting != ""){
          this.CreateMeetingDTO.settings.meeting_start_date = this.startMeeting;
        }
        if(this.endMeeting != ""){
          this.CreateMeetingDTO.settings.meeting_end_date = this.endMeeting;
        }
        try {
          if (result.data.boardId != "") {
            await this.getMeetings();
            let meeting = this.nuiteqMeetings.filter(function (item) {
              return item.id == result.data.boardId;
            });
            if (meeting.length != 0) {
              this.selectedMeeting = {
                name: meeting[0].name,
                id: meeting[0].id,
              };
            } else {
              this.generateStageMeeting();
            }
            this.InitDone();
          } else {
            this.InitDone();
          }
        } catch {
          console.log("Could not GetInformation2");
          return;
        }
      } catch {
        console.log("Could not GetInformation");
        return;
      }
    },
    async joinBoard(boardId) {
      try {
        let result = await this.$http.get(
          this.user.hostname + "/nuiteq/connect/" + boardId
        );
        window.open(result.data);
      } catch {
        console.log("Could not join Board");
      }
    },
    async GetSettings() {
      this.loading = true;
      let result = await this.$http.post(this.user.hostname + "/settings/get", {
        setting: "Nuiteq",
        organisation_uuid: this.user.information.organisation.organisation_uuid,
        functionbox_uuid: "",
      });
      return result.data;
    },
    async okSave() {
      this.loading = true;
      let NuiteqMeetingDTO = {
        name: this.meetingName,
        password: this.meetingPassword,
        waitingRoom: this.meetingWaitingroom,
      };
      try {
        let result = await this.$http.post(
          this.user.hostname + "/nuiteq/new",
          NuiteqMeetingDTO
        );
        this.$noty.info(this.$t("SAVED"));
        this.cancelSave();
        this.CreateMeetingDTO.boardId = result.data.boardId;
        this.selectedMeeting = {
          name: NuiteqMeetingDTO.name,
          id: result.data.boardId,
        };
        this.loading = false;
        this.cancelSave();
      } catch (error) {
        this.loading = false;
      }
    },
    cancelSave() {
      this.meetingName = "";
      this.meetingPassword = "";
      this.meetingWaitingroom = false;
      this.meetingPasswordType = "text";
    },
    async authenticatedBtn() {
      try {
        let result = await this.$http.post(
          this.user.hostname + "/nuiteq/login",
          {
            username: this.username,
            password: this.password,
          }
        );
        this.nuiteqAuthenticated = result.data;
      } catch {
        console.log("Could not authenticate");
      }
    },
    async getAuthenticated() {
      try {
        let result = await this.$http.get(
          this.user.hostname + "/nuiteq/authenticated"
        );
        this.nuiteqAuthenticated = result.data;
        if (result.data) {
          this.getMeetings();
        } else {
          this.username = this.user.information.email;
        }
      } catch {
        console.log("Could not getAuthenticated");
      }
    },
    async getMeetings() {
      try {
        let result = await this.$http.get(this.user.hostname + "/nuiteq/list");
        this.nuiteqMeetings = result.data;
      } catch {
        console.log("Could not getMeetings");
      }
    },
    searchedUser(email) {
      if (!this.addresses.includes(email)) {
        this.addresses.push(email);
      }
    },
    async closeBtn() {
      this.$emit("ChangeShow", false);
    },
    async InitSettings() {
      if (this.user.information.signature != "") {
        this.CreateMeetingDTO.body =
          "\n\n----------------------------\n" +
          this.user.information.signature;
      }
      if (this.settings.subject != "") {
        this.CreateMeetingDTO.subject = this.settings.subject;
      }
      if (this.settings.email_body != "") {
        this.CreateMeetingDTO.external_text = this.settings.email_body;
      }
      this.InitDone();
    },
    async GetMethods() {
      let result = await this.$http.get(
        this.user.hostname + "/authenticate/external/methods"
      );
      return result.data;
    },
    async UpdateBtn(){
      if(this.recurrence)
      {
        this.showRecurringOptions = true;
      }else{
        this.UpdateMeeting(); 
      }
    },
    async UpdateMeeting(){
      this.loading = true;
      this.saving = true;
      try {
        let UpdateMeetingDTO = {
          messageUuid: this.OriginalDTO.message_uuid,
          currentStart: this.startMeeting != "" ? this.startMeeting : this.OriginalDTO.settings.meeting_start_date,
          currentEnd: this.endMeeting != "" ? this.endMeeting : this.OriginalDTO.settings.meeting_end_date,
          newStart: null,
          newEnd: null,
          newLocation: null,
          affectSubsequent: this.editRecurring == "MULTIPLE" ? true : false,
          affectAll: this.editRecurring == "ALL" ? true : false,
          externalText: this.CreateMeetingDTO.external_text != this.OriginalDTO.external_text ? this.CreateMeetingDTO.external_text : null,
          externalParticipants: this.CreateMeetingDTO.external_participants,
          recurrence: this.CreateMeetingDTO.recurrence,
          subject: this.CreateMeetingDTO.subject != this.OriginalDTO.subject ? this.CreateMeetingDTO.subject : null,
          body: this.CreateMeetingDTO.body != this.OriginalDTO.body ? this.CreateMeetingDTO.body : null
        };
        UpdateMeetingDTO.newStart = UpdateMeetingDTO.currentStart + "" != this.CreateMeetingDTO.settings.meeting_start_date + "" ? this.CreateMeetingDTO.settings.meeting_start_date : null;
        UpdateMeetingDTO.newEnd = UpdateMeetingDTO.currentEnd + "" != this.CreateMeetingDTO.settings.meeting_end_date + "" ? this.CreateMeetingDTO.settings.meeting_end_date : null;

        await this.$http.post(
          this.user.hostname + "/meetings/update",
          UpdateMeetingDTO
        );
        this.$noty.info(this.$t("CHANGED_MEETING"));
        this.loading = false;
        this.saving = false;
        this.$emit("SentMeeting");
        this.closeBtn();

      } catch (error) {
        this.showRecurringOptions = false;
        this.saving = false;
        this.loading = false;
      }
    },
    async saveMeeting(isDraft) {
      if (this.saving || this.isTyping || this.disabledSave) {
        return;
      }
      this.saving = true;
      clearTimeout(this.timer);
      this.loading = true;
      this.CreateMeetingDTO.isDraft = isDraft;

      try {
        let result = await this.$http.post(
          this.user.hostname + "/nuiteq/secure",
          this.CreateMeetingDTO
        );
        this.loading = false;
        if (isDraft) {
          this.CreateMeetingDTO.message_uuid = result.data.message_uuid;
        } else {
          if (this.isChange) {
            if (result.data.all_sent != undefined) {
              if (result.data.all_sent) {
                this.$noty.info(this.$t("CHANGED_MEETING"));
              } else {
                this.$noty.warning(this.$t("CHANGED_MEETING_NOT_SENT"));
              }
            } else {
              this.$noty.info(this.$t("CHANGED_MEETING"));
            }
          } else {
            if (result.data.all_sent != undefined) {
              if (result.data.all_sent) {
                this.$noty.info(this.$t("SENT_MEETING"));
              } else {
                this.$noty.warning(this.$t("SENT_MEETING_NOT_SENT"));
              }
            } else {
              this.$noty.info(this.$t("SENT_MEETING"));
            }
          }
          this.$emit("SentMeeting");
          this.closeBtn();
        }
        this.saving = false;
      } catch (error) {
        this.saving = false;
        this.loading = false;
      }
    }
  },
  computed: {
    eventExpired(){
      let now = new DateTime.local().toUTC().toISO();
      return now > this.CreateMeetingDTO.settings.meeting_end_date;
    },
    meetingsNotInTrash() {
      let filtered = this.nuiteqMeetings.filter(function (item) {
        return item.trash == false;
      });
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      return filtered.sort(compare);
    },
    GetTitle() {
      if (this.saving) {
        if (this.CreateMeetingDTO.isDraft) {
          return " (" + this.$t("SAVING") + " .. )";
        } else {
          return " (" + this.$t("SENDING") + " .. )";
        }
      }
      return "";
    },
    disabledStep2: function () {
      if (this.CreateMeetingDTO.subject.length >= 256) {
        return true;
      }      
      if (!this.validMeetingEnd) {
        return true;
      }
      if (!this.validMeetingStart) {
        return true;
      }
      return false;
    },
    disabledStep3: function () {
      if (this.disabledStep2) {
        return true;
      }
      if (!this.validatedFiles) {
        return true;
      }
      return false;
    },
    disabledSend: function () {
      if (this.disabledStep3) {
        return true;
      }
      if (this.CreateMeetingDTO.subject.length == "") {
        return true;
      }
      if (this.CreateMeetingDTO.external_participants.length == 0) {
        return true;
      }
      let allValidated =
        this.CreateMeetingDTO.external_participants.filter(function (
          searchItem
        ) {
          return searchItem.validated == true;
        }).length == this.CreateMeetingDTO.external_participants.length;
      if (!allValidated) {
        return true;
      }
      return false;
    },
    disabledSave() {
      return (
        !this.validatedBody ||
        !this.validatedExternalText ||
        !this.validatedSubject ||
        !this.validatedFiles ||
        !this.validMeetingStart ||
        !this.validMeetingEnd ||
        !this.validRecurrence
      );
    },
  },
  watch: {
    "CreateMeetingDTO.body": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.recurrence": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.external_participants": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        let allValidated = this.CreateMeetingDTO.external_participants.filter(
          function (searchItem) {
            return (
              searchItem.validated == true ||
              searchItem.authentication_method == "NOT_SET"
            );
          }
        );
        if (
          allValidated.length ==
          this.CreateMeetingDTO.external_participants.length
        ) {
          this.debounceSave();
        }
      }
    },
    "CreateMeetingDTO.external_text": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.settings.loa_level": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.settings.meeting_start_date": function (newStartDate) {
      if (this.CreateMeetingDTO && this.CreateMeetingDTO.settings) {
        this.CreateMeetingDTO.settings.meeting_start_date = newStartDate;
      }
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.settings.meeting_end_date": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.subject": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
  },
  mounted() {},
};
</script>
