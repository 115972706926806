<template>
  <span v-if="loaded">
    <span v-if="showComponent">
      <span v-if="!showMultiple">
        <b-button class="no-border" size="sm" @click="showSefos">
          <slot></slot>
        </b-button>
      </span>
      <span v-if="showMultiple">
        <b-dropdown class="" size="sm" toggle-class="no-border">
          <template #button-content><slot></slot></template>
          <b-dropdown-item href="#" @click="showSefos"
            ><img
              class="icon mr-2"
              src="/img/message_type/message.png"
              :alt="$t('MESSAGES.TYPE.message')"
            />
            {{ $t("MESSAGES.TYPE.message") }}</b-dropdown-item
          >
          <b-dropdown-item v-if="sdkVisible" href="#" @click="showSDK"
            ><img
              class="icon mr-2"
              src="/img/message_type/sdk-message.png"
              :alt="$t('MESSAGES.TYPE.sdk-message')"
            />
            {{ $t("MESSAGES.TYPE.sdk-message") }}</b-dropdown-item
          >
          <b-dropdown-item v-if="faxVisible" href="#" @click="showFax"
            ><img
              class="icon mr-2"
              src="/img/message_type/fax-message.png"
              :alt="$t('MESSAGES.TYPE.fax-message')"
            />
            {{ $t("MESSAGES.TYPE.fax-message") }}</b-dropdown-item
          >
        </b-dropdown>
      </span>
      <span :key="'AddMessageForward_' + showIndex">
        <AddSefosMessage
          isForward
          :show="showSefosMessage"
          :user_uuid="user_uuid"
          :message_uuid="message.message_uuid"
          :functionbox_uuid="functionbox_uuid"
          @ChangeShow="changeShowSefosMessage"
          @SentMessage="SentMessage"
        ></AddSefosMessage>

        <AddSDKMessage
          isForward
          v-if="sdkVisible"
          :show="showSdkMessage"
          :message_uuid="message.message_uuid"
          :functionbox_uuid="functionbox_uuid"
          @ChangeShow="changeShowSdkMessage"
          @SentMessage="SentMessage"
        ></AddSDKMessage>

        <AddFaxMessage
          isForward
          :show="showFaxMessage"
          :message_uuid="message.message_uuid"
          :functionbox_uuid="functionbox_uuid"
          @ChangeShow="changeShowFaxMessage"
          @SentMessage="SentMessage"
        ></AddFaxMessage>
      </span>
    </span>
  </span>
</template>
<script>
import AddSefosMessage from "./Message/SefosMessage";
import AddSDKMessage from "./Message/SDKMessage";
import AddFaxMessage from "./Message/FaxMessage";
export default {
  props: {
    replyAll: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
    message: {
      default: null,
      type: Object,
    },
    user_uuid: {
      default: "",
      type: String,
    },
  },
  components: {
    AddSefosMessage,
    AddSDKMessage,
    AddFaxMessage,
  },
  data() {
    return {
      showIndex: 0,
      showComponent: true,
      showSefosMessage: false,
      showSdkMessage: false,
      showFaxMessage: false,
    };
  },
  methods: {
    changeShowSefosMessage(val) {
      this.showIndex++;
      this.showSefosMessage = val;
    },
    changeShowSdkMessage(val) {
      this.showIndex++;
      this.showSdkMessage = val;
    },
    changeShowFaxMessage(val) {
      this.showIndex++;
      this.showFaxMessage = val;
    },
    showSefos() {
      this.showSefosMessage = true;
    },
    showFax() {
      this.showFaxMessage = true;
    },
    showSDK() {
      this.showSdkMessage = true;
    },
    SentMessage() {
      this.$emit("Changed");
    },
  },
  computed: {
    showMultiple() {
      if (this.functionbox_uuid == "") {
        return false;
      } else {
        return this.sdkVisible == true || this.faxVisible == true;
      }
    },
    composeText() {
      return this.$t("MESSAGES.COMPOSE") + " - SEFOS";
    },
    faxVisible() {
      if (this.functionbox_uuid != "") {
        let self = this;
        let fb = this.user.functionboxes.items.filter(function (item) {
          return item.functionbox_uuid == self.functionbox_uuid;
        });
        if (fb.length == 0) {
          return false;
        } else {
          if (fb[0].fax_enabled != undefined) {
            return fb[0].fax_enabled;
          }
        }
      }
      return false;
    },
    sdkVisible() {
      if (this.user.auth_loa == 3) {
        if (this.functionbox_uuid != "") {
          let self = this;
          let fb = this.user.functionboxes.items.filter(function (item) {
            return item.functionbox_uuid == self.functionbox_uuid;
          });
          if (fb.length == 0) {
            return false;
          } else {
            if (fb[0].sdk_enabled != undefined) {
              return fb[0].sdk_enabled;
            }
          }
        }
      }
      return false;
    },
    loaded() {
      return this.reply_subject != "" && this.message;
    },
  },
  mounted() {
    if (this.link_message) {
      this.showComponent = false;
    }
  },
};
</script>
