var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("span", [
        _vm.showComponent
          ? _c("span", [
              !_vm.showMultiple
                ? _c(
                    "span",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "no-border",
                          attrs: { size: "sm" },
                          on: { click: _vm.showSefos },
                        },
                        [_vm._t("default")],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showMultiple
                ? _c(
                    "span",
                    [
                      _c(
                        "b-dropdown",
                        {
                          attrs: { size: "sm", "toggle-class": "no-border" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [_vm._t("default")]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: { click: _vm.showSefos },
                            },
                            [
                              _c("img", {
                                staticClass: "icon mr-2",
                                attrs: {
                                  src: "/img/message_type/message.png",
                                  alt: _vm.$t("MESSAGES.TYPE.message"),
                                },
                              }),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("MESSAGES.TYPE.message"))
                              ),
                            ]
                          ),
                          _vm.sdkVisible
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: { click: _vm.showSDK },
                                },
                                [
                                  _c("img", {
                                    staticClass: "icon mr-2",
                                    attrs: {
                                      src: "/img/message_type/sdk-message.png",
                                      alt: _vm.$t("MESSAGES.TYPE.sdk-message"),
                                    },
                                  }),
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.$t("MESSAGES.TYPE.sdk-message")
                                      )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.faxVisible
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: { click: _vm.showFax },
                                },
                                [
                                  _c("img", {
                                    staticClass: "icon mr-2",
                                    attrs: {
                                      src: "/img/message_type/fax-message.png",
                                      alt: _vm.$t("MESSAGES.TYPE.fax-message"),
                                    },
                                  }),
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.$t("MESSAGES.TYPE.fax-message")
                                      )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "span",
                { key: "AddMessageForward_" + _vm.showIndex },
                [
                  _c("AddSefosMessage", {
                    attrs: {
                      isForward: "",
                      show: _vm.showSefosMessage,
                      user_uuid: _vm.user_uuid,
                      message_uuid: _vm.message.message_uuid,
                      functionbox_uuid: _vm.functionbox_uuid,
                    },
                    on: {
                      ChangeShow: _vm.changeShowSefosMessage,
                      SentMessage: _vm.SentMessage,
                    },
                  }),
                  _vm.sdkVisible
                    ? _c("AddSDKMessage", {
                        attrs: {
                          isForward: "",
                          show: _vm.showSdkMessage,
                          message_uuid: _vm.message.message_uuid,
                          functionbox_uuid: _vm.functionbox_uuid,
                        },
                        on: {
                          ChangeShow: _vm.changeShowSdkMessage,
                          SentMessage: _vm.SentMessage,
                        },
                      })
                    : _vm._e(),
                  _c("AddFaxMessage", {
                    attrs: {
                      isForward: "",
                      show: _vm.showFaxMessage,
                      message_uuid: _vm.message.message_uuid,
                      functionbox_uuid: _vm.functionbox_uuid,
                    },
                    on: {
                      ChangeShow: _vm.changeShowFaxMessage,
                      SentMessage: _vm.SentMessage,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }