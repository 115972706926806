<template>
  <span>
    <b-sidebar
      sidebar-class="big_sidebar"
      id="add-meeting"
      v-model="show"
      :title="GetTitle"
      right
      backdrop
      no-slide
      lazy
      shadow
      no-header-close
      no-close-on-backdrop
      no-close-on-esc
      @shown="shownSidebar"
      @hidden="closeBtn"
      v-if="CreateMeetingDTO"
    >
      <template #footer="{ hide }">
        <div v-show="step == 1" class="ml-2">
          <b-button @click="closeBtn">{{ $t("CLOSE") }}</b-button>
          <b-button
            v-if="!isChange"
            @click="saveMeeting(true)"
            :disabled="saving || disabledSave"
            variant="secondary"
            class="btn-fill"
            >{{ $t("SAVE_DRAFT") }}</b-button
          >

          <b-button
            :disabled="disabledStep2"
            @click="step = 2"
            variant="primary"
            class="btn-fill"
            >{{ $t("NEXT") }}</b-button
          >
          <b-button
              v-if="CreateMeetingDTO.message_uuid != '' && !isChange"
            @click="RemoveDraft"
            :disabled="saving"
            variant="danger"
            class="btn-fill float-right"
            >{{ $t("REMOVE") }}</b-button
          >
        </div>
        <div v-show="step == 2" class="ml-2">
          <b-button @click="step = 1">{{ $t("BACK") }}</b-button>

          <b-button
            v-if="!isChange"
            @click="saveMeeting(true)"
            :disabled="saving || disabledSave"
            variant="secondary"
            class="btn-fill"
            >{{ $t("SAVE_DRAFT") }}</b-button
          >

          <b-button
            :disabled="disabledStep3"
            v-if="!isChange"
            @click="step = 3"
            variant="primary"
            class="btn-fill"
            >{{ $t("NEXT") }}</b-button
          >
          
          <b-button
            :disabled="disabledStep3"
            v-if="isChange"
            @click="step = 4"
            variant="primary"
            class="btn-fill"
            >{{ $t("NEXT") }}</b-button
          >

          <b-button
            v-if="(CreateMeetingDTO.message_uuid != '' || message_uuid != '') && !isChange"
            @click="RemoveDraft"
            :disabled="saving"
            variant="danger"
            class="btn-fill float-right"
            >{{ $t("REMOVE") }}</b-button
          >
        </div>
        <div v-show="step == 3" class="ml-2">
          <b-button @click="step = 2">{{ $t("BACK") }}</b-button>

          <b-button
            v-if="!isChange"
            @click="saveMeeting(true)"
            :disabled="saving || disabledSave"
            variant="secondary"
            class="btn-fill"
            >{{ $t("SAVE_DRAFT") }}</b-button
          >

          <b-button
            @click="step = 4"
            variant="primary"
            class="btn-fill"
            >{{ $t("NEXT") }}</b-button
          >
          <b-button
            v-if="(CreateMeetingDTO.message_uuid != '' || message_uuid != '') && !isChange"
            @click="RemoveDraft"
            :disabled="saving"
            variant="danger"
            class="btn-fill float-right"
            >{{ $t("REMOVE") }}</b-button
          >
        </div>
        <div v-show="step == 4" class="ml-2">

          <b-button v-if="isChange" @click="step = 2">{{ $t("BACK") }}</b-button>
          <b-button v-if="!isChange" @click="step = 3">{{ $t("BACK") }}</b-button>


          <b-button
            v-if="!isChange"
            @click="saveMeeting(true)"
            :disabled="saving || disabledSave"
            variant="secondary"
            class="btn-fill"
            >{{ $t("SAVE_DRAFT") }}</b-button
          >


          <b-button
            :disabled="disabledSend"
            v-if="!isChange"
            @click="saveMeeting(false)"
            variant="primary"
            class="btn-fill"
            >{{ $t("SUBMIT") }}</b-button
          >

          <b-button
            :disabled="disabledSend"
            @click="UpdateBtn"            
            v-if="isChange"
            variant="primary"
            class="btn-fill"
            >{{ $t("UPDATE") }}</b-button
          >

          
          <b-button
              v-if="(CreateMeetingDTO.message_uuid != '' || message_uuid != '') && !isChange"
            @click="RemoveDraft"
            :disabled="saving"
            variant="danger"
            class="btn-fill float-right"
            >{{ $t("REMOVE") }}</b-button
          >
        </div>
      </template>
      <b-overlay :show="!CreateMeetingDTO.isDraft && saving" rounded="sm">
        <div class="pb-4" v-if="loaded">
          <div v-if="settings" class="px-3 py-2 view-content create-message">
            <div v-show="step == 1">
              <InputSubject
                v-model="CreateMeetingDTO.subject"
                :settings="settings"
                @valid="(n) => (validatedSubject = n)"
                :limit="256"
              ></InputSubject>

              <div class="info-header mt-2">{{ $t("ADD_RECIPIENTS") }}</div>

              <InputParticipants
                :message_uuid="CreateMeetingDTO.message_uuid"
                :key="'InputParticipants_' + externalParticipantsKey"
                message_type="jitsi-secure-meeting"
                is-meeting
                :participants="CreateMeetingDTO.participants"
                :external_participants="CreateMeetingDTO.external_participants"
                @IsTyping="IsTyping"
                @UpdatedParticipants="UpdatedParticipants"
              ></InputParticipants>

              <div class="info-header mt-2">{{ $t("TIME") }}</div>
              
             <b-alert show variant="warning" class="pl-1 pt-1 pb-1 pr-1" v-if="!isChange && eventExpired">
              {{ $t('EVENT_EXPIRED') }}
            </b-alert>

              <PickDate
                v-model="CreateMeetingDTO.settings.meeting_start_date"
                :use-label="$t('FROM')"
                use-clock
                @valid="(n) => (validMeetingStart = n)"></PickDate>

              <PickDate              
                :start-date="CreateMeetingDTO.settings.meeting_start_date"
                v-model="CreateMeetingDTO.settings.meeting_end_date"
                :use-label="$t('TO')"
                use-clock
                @valid="(n) => (validMeetingEnd = n)"></PickDate>


                <PickRecurrence
              v-model="CreateMeetingDTO.recurrence"
               @valid="(n) => (validRecurrence = n)"
               @changed="() => (recurrenceIndex += 1)"
              :startDate="CreateMeetingDTO.settings.meeting_start_date"
              :endDate="CreateMeetingDTO.settings.meeting_end_date"
              ></PickRecurrence>
              
              <PickRecurrence 
                :key="'recurrenceIndex_' + recurrenceIndex"
                :recurrence="CreateMeetingDTO.recurrence"
                :startDate="CreateMeetingDTO.settings.meeting_start_date"
                :endDate="CreateMeetingDTO.settings.meeting_end_date"
                text-only></PickRecurrence>


              <div class="info-header mt-2">{{ $t("ADD_FILES") }}</div>

              <div :key="'AddFiles' + CreateMeetingDTO.message_uuid">
                <InputFiles
                  :message_uuid="CreateMeetingDTO.message_uuid"
                  v-model="CreateMeetingDTO.attachments"
                  :cannot-upload="$t('DRAFT.SEFOS_UPLOAD')"
                  :max-size="200"
                  @valid="(n) => (validatedFiles = n)"
                ></InputFiles>
              </div>
            </div>

            <div v-show="step == 2">
              <div class="info-header mt-2">
                {{ $t("INVITATION_INFORMATION") }}
              </div>

              <InputExternalText
                v-model="CreateMeetingDTO.external_text"
                @valid="(n) => (validatedExternalText = n)"
                :settings="settings"
                :rows="5"
                is-meeting
                :limit="1500"
              ></InputExternalText>

              <div class="info-header mt-2">{{ $t("MEETING_NOTES") }}</div>

              <InputBody
                v-model="CreateMeetingDTO.body"
                :settings="settings"
                @valid="(n) => (validatedBody = n)"
                :limit="20000"
                :rows="8"
              ></InputBody>
            </div>

            <div v-show="step == 3">
              <div class="info-header mt-2">{{ $t("MESSAGES.LOA_LEVELS") }}</div>

              <InputLoa v-model="CreateMeetingDTO.settings.loa_level"></InputLoa>
            </div>

            <div v-show="step == 4">
              <div class="info-header mt-2">
                {{ $t("MESSAGES.NOT_REGISTERED_INFORMATION") }}
              </div>

              <div class="mt-2" :key="'NotRegistedLoa_' + CreateMeetingDTO.settings.loa_level">
                <b-alert
                  show
                  variant="warning"
                  class="mb-2"
                  v-if="CreateMeetingDTO.external_participants.length == 0"
                  >{{ $t("NO_RECIPIENTS") }}</b-alert
                >
                <div
                  v-for="(
                    item, index
                  ) in CreateMeetingDTO.external_participants"
                  :key="'NotRegistered_' + index"
                  class="NotRegistered"
                >
                  <NotRegistered
                    @change="UpdatedNotRegistered"
                    v-model="CreateMeetingDTO.external_participants[index]"
                    @remove="RemoveNotRegistered(index)"
                    message_type="message"
                    :methods="methods"
                    :showSefos="item.showSefos"
                    :loa_level="CreateMeetingDTO.settings.loa_level"
                  ></NotRegistered>
                </div>
              </div>
              <b-button
              @click="step = 1"
              variant="secondary"
              >{{ $t("ADD") }}</b-button
            >
            </div>
          </div>
        </div>
      </b-overlay>

      <b-modal :ok-title="$t('EDIT')" @ok="UpdateMeeting" hide-header ok-variant="primary" centered v-model="showRecurringOptions" id="editMeetingModal">
        <b-form-group>
          <b-form-radio v-model="editRecurring" name="edit-recurring-single" value="SINGLE">{{ $t('EDIT_RECURRENCE_SINGLE') }}</b-form-radio>
          <b-form-radio v-model="editRecurring" name="edit-recurring-multiple" value="MULTIPLE">{{ $t('EDIT_RECURRENCE_MULTIPLE') }}</b-form-radio>
          <b-form-radio v-model="editRecurring" name="edit-recurring-all" value="ALL">{{ $t('EDIT_RECURRENCE_ALL') }}</b-form-radio>              
        </b-form-group>
      </b-modal>


    </b-sidebar>
  </span>
</template>

<script>
import { DateTime } from "luxon";
import InputSubject from "../../Input/Subject";
import InputBody from "../../Input/Body";
import InputExternalText from "../../Input/ExternalText";
import InputParticipants from "../../Input/Participants";
import InputFiles from "../../Input/Files";
import NotRegistered from "../../Compose/NotRegistered";
import PickDate from "../../Input/PickDate";
import PickRecurrence from "../../Input/PickRecurrence";
import InputLoa from "../../Input/Loa";
export default {
  props: {
    isChange: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    show: {
      default: false,
      type: Boolean,
    },
    message_uuid: {
      default: "",
      type: String,
    },
    startMeeting: {
      default: "",
      type: String,
    },
    endMeeting: {
      default: "",
      type: String,
    }
  },
  components: {
    InputSubject,
    InputBody,
    InputExternalText,
    InputParticipants,
    InputFiles,
    NotRegistered,
    PickDate,
    PickRecurrence,
    InputLoa
  },
  data() {
    return {
      editRecurring: "SINGLE",
      showRecurringOptions: false,
      externalParticipantsKey: 0,
      recurrenceIndex: 0,
      validRecurrence: true,
      validMeetingStart: true,
      validMeetingEnd: true,
      validatedSubject: true,
      validatedBody: true,
      validatedExternalText: true,
      saving: false,
      isTyping: false,
      timeout: 2000,
      canSave: false,
      loaded: false,
      timer: null,
      validatedFiles: true,
      OriginalDTO: null,
      CreateMeetingDTO: {
        join_url: "",
        boardId: "",
        recurrence: null,
        user_uuid: "",
        functionbox_uuid: "",
        action: "INIT",
        message_uuid: "",
        conversation_uuid: "",
        subject: "",
        body: "",
        attachments: [],
        participants: [],
        external_text: "",
        external_participants: [],
        settings: {
          loa_level: 1,
          open_to_date: null,
          open_from_date: null,
          require_response: 1,
          meeting_start_date: "",
          meeting_end_date: "",
        },
        custom_identifier: "",
        client: "web",
        response_to_message_uuid: this.message_uuid,
        webhook: "",
        isDraft: true,
      },
      settings: null,
      forceRedrawRecipientsIndex: 0,
      step: 1,
      methods: [],
      loading: false,
      isDateTimeValid: false,
      recurrence: null
    };
  },
  methods: {
    RemoveNotRegistered(index)
    {
      this.SefosDTO.external_participants.splice(index, 1);
      this.externalParticipantsKey++;
    },
    async RemoveDraft() {
      this.loading = true;
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.$http
              .post(self.user.hostname + "/meetings/secure/remove", {
                message_uuid: self.CreateMeetingDTO.message_uuid == "" ? self.message_uuid : self.CreateMeetingDTO.message_uuid,
                user_uuid: "",
                functionbox_uuid: "",
              })
              .then(() => {
                self.loading = false;
                self.$noty.info(self.$t("REMOVED"));
                self.closeBtn();
              })
              .catch(() => {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
    UpdatedParticipants(updated) {
      this.CreateMeetingDTO.participants = updated.participants;
      this.CreateMeetingDTO.external_participants =
        updated.external_participants;
    },
    debounceSave() {
      if (!this.isChange) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.saveMeeting(true);
        }, this.timeout);
      }
    },
    UpdatedNotRegistered() {
      this.debounceSave();
    },
    IsTyping(val) {
      this.isTyping = val;
    },
    async closeBtn() {
      this.$emit("ChangeShow", false);
    },
    async shownSidebar() {
      await this.$store.dispatch("user/fetchUser");
      this.settings = await this.GetSettings();
      this.methods = await this.GetMethods();
      if (this.settings && this.methods) {
        if (this.message_uuid != "") {
          await this.GetInformation();
        } else {
          this.InitSettings();
        }
      } else {
        console.error("Failed to load settings or methods");
      }
    },
    getSettings() {
      let self = this;
      self.loading = true;
      this.$http
        .post(this.user.hostname + "/settings/get", {
          setting: "JitsiSecure",
          organisation_uuid:
            this.user.information.organisation.organisation_uuid,
          functionbox_uuid: "",
        })
        .then(function (result) {
          self.settings = result.data;
          self.loading = false;
          self.init();
        })
        .catch(function () {
          self.loading = false;
        });
    },
    async InitSettings() {
      if (this.user.information.signature != "") {
        this.CreateMeetingDTO.body =
          "\n\n----------------------------\n" +
          this.user.information.signature;
      }
      if (this.settings.subject != "") {
        this.CreateMeetingDTO.subject = this.settings.subject;
      }
      if (this.settings.email_body != "") {
        this.CreateMeetingDTO.external_text = this.settings.email_body;
      }
      this.InitDone();
    },
    InitDone() {
      this.loading = false;
      this.loaded = true;
      setTimeout(() => {
        this.canSave = true;
      }, this.timeout);
      this.CreateMeetingDTO.action = "NEW";
    },
    async GetSettings() {
      this.loading = true;
      let result = await this.$http.post(this.user.hostname + "/settings/get", {
        setting: "Jitsi",
        organisation_uuid: this.user.information.organisation.organisation_uuid,
        functionbox_uuid: "",
      });
      return result.data;
    },
    async GetInformation() {
      try {
        let result = await this.$http.get(
          this.user.hostname + "/meetings/info/" + this.message_uuid
        );       
        this.CreateMeetingDTO = result.data;  
        this.OriginalDTO = JSON.parse(JSON.stringify(result.data))

        this.recurrence = result.data.recurrence;
        if(this.startMeeting != ""){
          this.CreateMeetingDTO.settings.meeting_start_date = this.startMeeting;
        }
        if(this.endMeeting != ""){
          this.CreateMeetingDTO.settings.meeting_end_date = this.endMeeting;
        }
        this.InitDone();  
        this.loaded = true;
      } catch {
        console.log("Could not GetInformation");
      }
    },
    async GetMethods() {
      let result = await this.$http.get(
        this.user.hostname + "/authenticate/external/methods"
      );
      return result.data;
    },
    async UpdateBtn(){
      if(this.recurrence)
      {
        this.showRecurringOptions = true;
      }else{
        this.UpdateMeeting(); 
      }
    },
    async UpdateMeeting(){
      this.loading = true;
      this.saving = true;
      try {
        let UpdateMeetingDTO = {
          messageUuid: this.OriginalDTO.message_uuid,
          currentStart: this.startMeeting != "" ? this.startMeeting : this.OriginalDTO.settings.meeting_start_date,
          currentEnd: this.endMeeting != "" ? this.endMeeting : this.OriginalDTO.settings.meeting_end_date,
          newStart: null,
          newEnd: null,
          newLocation: null,
          affectSubsequent: this.editRecurring == "MULTIPLE" ? true : false,
          affectAll: this.editRecurring == "ALL" ? true : false,
          externalText: this.CreateMeetingDTO.external_text != this.OriginalDTO.external_text ? this.CreateMeetingDTO.external_text : null,
          externalParticipants: this.CreateMeetingDTO.external_participants,
          recurrence: this.CreateMeetingDTO.recurrence,
          subject: this.CreateMeetingDTO.subject != this.OriginalDTO.subject ? this.CreateMeetingDTO.subject : null,
          body: this.CreateMeetingDTO.body != this.OriginalDTO.body ? this.CreateMeetingDTO.body : null
        };
        UpdateMeetingDTO.newStart = UpdateMeetingDTO.currentStart + "" != this.CreateMeetingDTO.settings.meeting_start_date + "" ? this.CreateMeetingDTO.settings.meeting_start_date : null;
        UpdateMeetingDTO.newEnd = UpdateMeetingDTO.currentEnd + "" != this.CreateMeetingDTO.settings.meeting_end_date + "" ? this.CreateMeetingDTO.settings.meeting_end_date : null;

        await this.$http.post(
          this.user.hostname + "/meetings/update",
          UpdateMeetingDTO
        );
        this.$noty.info(this.$t("CHANGED_MEETING"));
        this.loading = false;
        this.saving = false;
        this.$emit("SentMeeting");
        this.closeBtn();

      } catch (error) {
        this.showRecurringOptions = false;
        this.saving = false;
        this.loading = false;
      }
    },
    async saveMeeting(isDraft) {
      if (this.saving || this.isTyping || this.disabledSave) {
        return;
      }
      this.saving = true;
      clearTimeout(this.timer);
      this.loading = true;
      this.CreateMeetingDTO.isDraft = isDraft;

      if (
        !this.CreateMeetingDTO.recurrence ||
        this.CreateMeetingDTO.recurrence.frequency === "None"
      ) {
        this.CreateMeetingDTO.recurrence = null;
      }

      try {
        let result = await this.$http.post(
          this.user.hostname + "/jitsi/confidentially/secure",
          this.CreateMeetingDTO
        );
        this.loading = false;
        if (isDraft) {
          this.CreateMeetingDTO.message_uuid = result.data.message_uuid;
          this.saving = false;
        } else {
          if (this.isChange) {
            if (result.data.all_sent != undefined) {
              if (result.data.all_sent) {
                this.$noty.info(this.$t("CHANGED_MEETING"));
              } else {
                this.$noty.warning(this.$t("CHANGED_MEETING_NOT_SENT"));
              }
            } else {
              this.$noty.info(this.$t("CHANGED_MEETING"));
            }
          } else {
            if (result.data.all_sent != undefined) {
              if (result.data.all_sent) {
                this.$noty.info(this.$t("SENT_MEETING"));
              } else {
                this.$noty.warning(this.$t("SENT_MEETING_NOT_SENT"));
              }
            } else {
              this.$noty.info(this.$t("SENT_MEETING"));
            }
          }
          this.$emit("SentMeeting");
          this.closeBtn();
        }
      } catch (error) {
        this.saving = false;
        this.loading = false;
      }
    }
  },
  computed: {
    eventExpired(){
      let now = new DateTime.local().toUTC().toISO();
      return now > this.CreateMeetingDTO.settings.meeting_end_date;
    },
    disabledSave() {
      return (
        !this.validatedBody ||
        !this.validatedExternalText ||
        !this.validatedSubject ||
        !this.validatedFiles ||
        !this.validMeetingStart ||
        !this.validMeetingEnd ||
        !this.validRecurrence
      );
    },
    disabledStep2() {
      if (this.CreateMeetingDTO.subject.length >= 256) {
        return true;
      }
      if (!this.validMeetingStart) {
        return true;
      }
      if (!this.validMeetingEnd) {
        return true;
      }
      return false;
    },
    disabledStep3() {
      if (this.disabledStep2) {
        return true;
      }
      if (this.CreateMeetingDTO.body.length >= 20000) {
        return true;
      }
      if (!this.validatedFiles) {
        return true;
      }
      return false;
    },
    disabledSend() {
      if (this.disabledSave) {
        return true;
      }
      if (this.disabledStep3) {
        return true;
      }
      if (this.CreateMeetingDTO.subject.length == "") {
        return true;
      }
      if (this.CreateMeetingDTO.external_participants.length == 0) {
        return true;
      }
      let allValidated =
        this.CreateMeetingDTO.external_participants.filter(function (
          searchItem
        ) {
          return searchItem.validated == true;
        }).length == this.CreateMeetingDTO.external_participants.length;
      if (!allValidated) {
        return true;
      }
      return false;
    },
    GetTitle() {
      if (this.saving) {
        return `${this.$t("MESSAGES.TYPE.jitsi-secure-meeting")} (${
          this.CreateMeetingDTO.isDraft ? this.$t("SAVING") : this.$t("SENDING")
        } .. )`;
      }
      return this.$t("MESSAGES.TYPE.jitsi-secure-meeting");
    }
  },
  watch: {
    "CreateMeetingDTO.body": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.recurrence": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.external_participants": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        let allValidated = this.CreateMeetingDTO.external_participants.filter(
          function (searchItem) {
            return (
              searchItem.validated == true ||
              searchItem.authentication_method == "NOT_SET"
            );
          }
        );
        if (
          allValidated.length ==
          this.CreateMeetingDTO.external_participants.length
        ) {
          this.debounceSave();
        }
      }
    },
    "CreateMeetingDTO.external_text": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.settings.loa_level": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.settings.meeting_start_date": function (newStartDate) {
      if (this.CreateMeetingDTO && this.CreateMeetingDTO.settings) {
        this.CreateMeetingDTO.settings.meeting_start_date = newStartDate;
      }
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.settings.meeting_end_date": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
    "CreateMeetingDTO.subject": function () {
      if (this.CreateMeetingDTO.action != "INIT" && this.canSave) {
        this.debounceSave();
      }
    },
  },
  mounted() {},
};
</script>
